import React from 'react';

class NotFoundPage extends React.Component {
  render() {
    return (
		<p>I haven’t written this codesnack yet.</p> 
    );
  }
}

export default NotFoundPage;